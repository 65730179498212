.ant-tabs-ink-bar {
  background: transparent;
  height: 2px;
}

.ant-tabs-ink-bar::after {
  background: rgb(0, 183, 255);
  bottom: 0;
  content: " ";
  height: 2px;
  left: 50%;
  position: absolute;
  right: 0;
  transform: translateX(-50%);
  width: 100px;
}


.react-resizable {
	position: relative;
	background-clip: padding-box;
}

.react-resizable-handle {
	position: absolute;
	width: 10px;
	height: 100%;
	bottom: 0;
	right: -5px;
	cursor: col-resize;
	z-index: 1;
}

.jsonGlyphMarginClass {
	background: darkgreen;
}
.jsonHighLightedContentClass {  
	background: darkslategrey;
}